import React from 'react'
import { graphql } from 'gatsby'
import { HomePageTemplate } from '../templates/HomePage'
require('typeface-oswald')

export default class IndexPage extends React.Component {
  render() {
    const { frontmatter } = this.props.data.markdownRemark

    return (
      <HomePageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        legal={frontmatter.legal}
        socialMedia={frontmatter.socialMedia}
        downloadLinks={frontmatter.downloadLinks}
        hero={frontmatter.hero}
        about={frontmatter.about}
        feature1={frontmatter.feature1}
        feature2={frontmatter.feature2}
        feature3={frontmatter.feature3}
        feature4={frontmatter.feature4}
        feature5={frontmatter.feature5}
        newsletter={frontmatter.newsletter}
        contact={frontmatter.contact}
      />
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter:{templateKey: {eq: "HomePage"}}) {
      frontmatter {
        title
        description
        legal {
          label
          value
        }
        socialMedia {
          icon
          url
        }
        downloadLinks {
          icon
          url
          altText
        }
        hero {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        about {
          backgroundImage
          backgroundColor
          video
          image
          title
          subTitle
          socialTitle
          description
        }
        feature1 {
          backgroundImage
          backgroundColor
          title
          subTitle
          description
          testimonials {
            author
            image
          }
        }
        feature2 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature3 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature4 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature5 {
          backgroundImage
          backgroundColor
          title
          subTitle
          alignCenter
          description
        }
        newsletter {
          title
          subTitle
          description
        }
        contact {
          title
          subTitle
          description
        }
      }
    }
  }
`
